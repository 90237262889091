<script>
import { global } from './utils'
export default {
  name: 'EventCard',
  props: { event: Object, language: String, permanentEventLabel: String },
  data() {
    return {
      global,
      showEditorCardOverlay: false,
      errors: {},
      loadings: {},
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    getEventLocation(event) {
      return event.meta && event.meta.location && event.meta.location.text
    },
    getEventTags(event) {
      return event.meta && event.meta.tags
    },
    getEventCategories(event) {
      return event.categories
    },
    getEventDate(event) {
      if (event.meta && event.meta.date && event.meta.date.type === 'single') {
        return this.formatDateFromString(event.meta.date.end, this.localeCode)
      } else if (
        event.meta &&
        event.meta.date &&
        event.meta.date.type === 'range'
      ) {
        return (
          this.formatDateFromString(event.meta.date.start, this.localeCode) +
          ' - ' +
          this.formatDateFromString(event.meta.date.end, this.localeCode)
        )
      } else {
        return this.permanentEventLabel
      }
    },
    getEventHour(event) {
      if (event.meta && event.meta.date && event.meta.date.type === 'single') {
        if (
          this.event.meta &&
          this.event.meta.date &&
          this.event.meta.date.hour
        ) {
          return this.formatHourFromString(
            this.event.meta.date.hour,
            this.localeCode
          )
        }
      }
      return false
    },
    formatDateFromString(dateString, locale) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(
        date
      )
    },
    formatHourFromString(hourString, locale) {
      const date = new Date('1970-01-01T' + hourString)
      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
      }).format(date)
    },
    deleteEvent(confirmText = false) {
      let confirm = false
      if (confirmText) {
        confirm = window.confirm(confirmText)
      } else {
        confirm = window.confirm('This action cannot be undone, are you sure?')
      }
      if (confirm) {
        this.deleteEventEndpoint()
      } else {
        this.global.debug('deleteEvent canceled')
      }
    },
    deleteEventEndpoint() {
      this.setLoading('deleteEventLoadingEndpoint')
      this.clearError('deleteEventLoadingEndpoint')
      this.global.debug('deleting event')
      this.axios
        .delete(
          this.$root.apiUrl + '/agitator/events/' + this.event.id,
          {},
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          location.reload()
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('deleteEventLoadingEndpoint')
          this.clearLoading('deleteEventLoadingEndpoint')
        })
        .finally(() => this.clearLoading('deleteEventLoadingEndpoint'))
    },
  },
  computed: {
    mainImageSrc() {
      return this.event.main_image
        ? this.global.getCdnImage(this.event.main_image, 600, 315)
        : null
    },
    localeCode() {
      // if (this.language.toLowerCase() === 'en') {
      //   return 'en-US'
      // }
      // if (this.language.toLowerCase() === 'is') {
      //   return 'is' // NOTE: will not work in Chrome, icelandic locale is not supported
      // }
      // default fallback
      return 'en-GB'
    },
  },
}
</script>

<style scoped lang="scss">
.carousel-caption.status {
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  &.stage-2 {
    background-color: green;
  }
  &.stage-0 {
    background-color: gray;
  }
  &.stage-4 {
    background-color: yellow;
    color: #000;
  }
}
</style>
